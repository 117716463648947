import { TrainingPlanContext } from '../types'
import { PlanCostData, totalPlanCost } from './plan_cost'

interface PlanCostProps {
  remainingPerLearner: PlanCostData
  consumedPerLearner: Record<number, number>
  trainingPlanContext: TrainingPlanContext
}

const PlanCostDisplay = ({ remainingPerLearner, trainingPlanContext, consumedPerLearner }: PlanCostProps) => {
  const { userIdsToRemove } = trainingPlanContext
  const cost = totalPlanCost({
    costPerLearner: remainingPerLearner,
    userIdsToRemove
  })

  const totalConsumed = Object.values(consumedPerLearner).reduce((total, value) => total + value, 0)

  return (
    <>
      <div className="my-5">
        <span className="font-semibold">
          Remaining Credits: {Math.round(cost.mandatory)} credits ({Math.round(cost.includingOptional)} credits
          including optional sessions)
          <span className="sw-popover ml-1">
            <span>ℹ️</span>
            <div className="popover-content">
              <p>Assuming listed module durations and that every learner hour is charged at 1 credit</p>
              (This may change with catch-ups etc.)
            </div>
          </span>
        </span>
      </div>
      <div className="my-5">
        <span className="font-semibold">
          Consumed Credits: {Math.round(totalConsumed)} credits
          <span className="sw-popover ml-1">
            <span>ℹ️</span>
            <div className="popover-content">
              <p>Credits split evenly across participants when involving learners from other plans.</p>
              Includes late declines, hidden sessions and inactive, paused and completed learners.
            </div>
          </span>
        </span>
      </div>
    </>
  )
}

export default PlanCostDisplay
