import { useCallback, useMemo, useState } from 'react'
import { CoachAvailabilityEditorProps, CoachTimetableEventData } from '../generated_types/coach_availability'
import { Calendar, Formats, SlotInfo, Views, luxonLocalizer } from '@skiller-whale/react-big-calendar'
import withDragAndDrop, { EventInteractionArgs } from '@skiller-whale/react-big-calendar/lib/addons/dragAndDrop'
import '@skiller-whale/react-big-calendar/lib/css/react-big-calendar.css'
import '@skiller-whale/react-big-calendar/lib/addons/dragAndDrop/styles.css'

import { apiRequest } from '../utils/json_request'
import Errors, { ModelErrors } from '../library/errors'
import { FlashAlert } from '../library/flash_message'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import ShowAllDayButton from './show_all_day_button'
import { DateTime, Settings } from 'luxon'
import Toolbar from './toolbar'
import { UserCoachAvailabilitiesPath, ProfileCoachAvailabilitiesPath } from '../utils/api/paths'
import { CalendarEvent } from './types'
import SidebarContent from './sidebar_content'

const DnDCalendar = withDragAndDrop(Calendar<CalendarEvent>)

const CoachAvailabilityEditor = ({
  coach_timetable_events: initialEvents,
  date,
  user_id,
  timezones,
  can_edit_sessions,
  include_explanation,
  calendar_classes
}: CoachAvailabilityEditorProps) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>()

  const [editable, setEditable] = useState(false)
  const [timezone, setTimezone] = useState(timezones[0])

  const [loading, setLoading] = useState(false)
  const [showAllDay, setShowAllDay] = useState(false)
  const [coachTimetableEvents, setCoachTimetableEvents] = useState<CalendarEvent[]>(() =>
    initialEvents.map(formatEvent)
  )

  const [errors, setErrors] = useState<ModelErrors | undefined>()

  const { defaultDate, min, max, localizer } = useMemo(() => {
    Settings.defaultZone = timezone.value
    const defaultDate = DateTime.fromISO(date)
    return {
      defaultDate: defaultDate.toJSDate(),
      min: defaultDate.set({ hour: 6 }).toJSDate(),
      max: defaultDate.set({ hour: 22 }).toJSDate(),
      localizer: luxonLocalizer(DateTime)
    }
  }, [timezone, date])

  const setEventLoading = useCallback((id: number, value: boolean) => {
    setCoachTimetableEvents(current =>
      current.map(event =>
        event.type === 'coach_availability' && event.id === id ? { ...event, loading: value } : event
      )
    )
  }, [])

  const { events, backgroundEvents } = useMemo(() => {
    const events = coachTimetableEvents.filter(
      ev => (editable && ev.type === 'coach_availability') || (!editable && ev.type !== 'coach_availability')
    )
    const backgroundEvents = coachTimetableEvents.filter(ev => !editable && ev.type === 'coach_availability')
    return { events, backgroundEvents }
  }, [coachTimetableEvents, editable])

  const handleSelectSlot = useCallback(
    async (slot: SlotInfo) => {
      if (!editable) {
        return
      }

      const placeholder = {
        type: 'placeholder',
        loading: true,
        start: slot.start,
        end: slot.end
      } as const

      setCoachTimetableEvents(existing => existing.concat(placeholder))

      const url: UserCoachAvailabilitiesPath | typeof ProfileCoachAvailabilitiesPath = user_id
        ? `/users/${user_id}/coach_availabilities`
        : '/profile/coach_availabilities'

      const result = await apiRequest(url, {
        method: 'POST',
        payload: {
          coach_availability: {
            projected_start: slot.start.toISOString(),
            projected_finish: slot.end.toISOString()
          }
        }
      })

      if (result.ok) {
        setErrors(undefined)
        setCoachTimetableEvents(existing =>
          existing
            .concat(result.data.coach_timetable_events.map(event => formatEvent(event)))
            .filter(event => event !== placeholder)
        )
      } else {
        setErrors(result.errors)
        setCoachTimetableEvents(existing => existing.filter(event => event !== placeholder))
      }
    },
    [editable, user_id]
  )

  const handleEventUpdate = useCallback(
    async ({ event, start, end }: EventInteractionArgs<CalendarEvent>) => {
      if (event.type !== 'coach_availability') return
      setEventLoading(event.id, true)
      const result = await apiRequest(`/coach_availabilities/${event.id}`, {
        method: 'PATCH',
        payload: {
          coach_availability: {
            projected_start: toISOString(start),
            projected_finish: toISOString(end)
          }
        }
      })
      if (result.ok) {
        setCoachTimetableEvents(existing => {
          const without = existing.filter(
            // remove the edited events
            e => !(e.type === 'coach_availability' && e.id === event.id)
          )
          return without.concat(result.data.coach_timetable_events.map(event => formatEvent(event)))
        })
        setErrors(undefined)
      } else {
        setEventLoading(event.id, false)
        setErrors(result.errors)
      }
    },
    [setEventLoading]
  )

  const handleEventDelete = useCallback(
    async event => {
      if (event.type !== 'coach_availability') return
      setEventLoading(event.id, true)
      const result = await apiRequest(`/coach_availabilities/${event.id}`, {
        method: 'DELETE',
        payload: undefined
      })
      if (result.ok) {
        setCoachTimetableEvents(existing =>
          existing.filter(e => !(e.type === 'coach_availability' && e.id === event.id))
        )
        setErrors(undefined)
      } else {
        setEventLoading(event.id, false)
        setErrors(result.errors)
      }
    },
    [setEventLoading]
  )

  const components = useMemo(
    () => ({
      event: editable ? withDelete({ onDelete: handleEventDelete }) : EventDisplay,
      timeGutterHeader: () => <ShowAllDayButton showAllDay={showAllDay} setShowAllDay={setShowAllDay} />,
      toolbar: Toolbar({ timezones, setTimezone, timezone, setEditable, editable })
    }),
    [handleEventDelete, editable, showAllDay, timezone, timezones, setTimezone]
  )

  const draggable = useCallback((event: CalendarEvent) => editable && event.type === 'coach_availability', [editable])
  const eventPropGetter = useCallback((event: CalendarEvent) => eventClassNames(event, editable), [editable])

  const handleDateChanged = useCallback(
    async (newDate: Date) => {
      const url: UserCoachAvailabilitiesPath | typeof ProfileCoachAvailabilitiesPath = user_id
        ? `/users/${user_id}/coach_availabilities`
        : '/profile/coach_availabilities'
      setLoading(true)
      setSelectedEvent(undefined)
      const result = await apiRequest(url, {
        method: 'GET',
        payload: undefined,
        // we want the date in local time zone: if the current TZ has a positive UTC offset then when the user has selected 'monday 1st april'
        // then the utc date time will be march 31st at some time, so we would submit 2024-03-31T... which the backend would process as
        // 'please show the events for the week containing 2024-03-31', ie the previous week
        query: { date: DateTime.fromJSDate(newDate).toISODate()! }
      })
      setLoading(false)
      if (result.ok) {
        setCoachTimetableEvents(result.data.coach_timetable_events.map(ev => formatEvent(ev)))
        setErrors(undefined)
      } else {
        setErrors(result.errors)
      }
    },
    [user_id]
  )

  return (
    <>
      {errors && (
        <FlashAlert sticky onClose={() => setErrors(undefined)}>
          <ul className="list-inside">
            <Errors errors={errors} as="li" />
          </ul>
        </FlashAlert>
      )}

      <h3>{editable ? 'Availability for Coaching' : 'Schedule'}</h3>

      {include_explanation && (
        <>
          <p>
            To edit your availability, click the pencil icon then click and drag over the times you&apos;ll typically be
            available for coaching.
          </p>
          <ul className="list-inside">
            <li>
              This should be indicative, we&apos;ll still always check with you before starting a new group, or moving a
              group to a new regular time slot.
            </li>
            <li>
              We may still ask you about other times you haven’t selected here, but you&apos;re more likely to be asked
              about times you&apos;ve marked as available.
            </li>
          </ul>
        </>
      )}

      <div className={`relative mt-8 ${calendar_classes}`}>
        {loading && (
          <div className="absolute w-full h-full sw-loading loading-lg pointer-events-auto opacity-50 transition-opacity z-10 bg-white"></div>
        )}
        <div className="absolute flex w-full gap-2">
          <div className="grow">
            <DnDCalendar
              components={components}
              events={events}
              backgroundEvents={backgroundEvents}
              min={showAllDay ? undefined : min}
              max={showAllDay ? undefined : max}
              defaultView={Views.WORK_WEEK}
              views={ENABLED_VIEWS}
              defaultDate={defaultDate}
              localizer={localizer}
              formats={FORMATS}
              selectable={editable}
              onSelectSlot={handleSelectSlot}
              onEventResize={handleEventUpdate}
              onEventDrop={handleEventUpdate}
              resizableAccessor={draggable}
              draggableAccessor={draggable}
              eventPropGetter={eventPropGetter}
              dayLayoutAlgorithm={'no-overlap'}
              onNavigate={handleDateChanged}
              autoscrollWhenDragging={false}
              onSelectEvent={setSelectedEvent}
            />
          </div>
          {selectedEvent && selectedEvent.type === 'training_session' && (
            <div className="basis-72 sw-card py-2">
              <SidebarContent
                event={selectedEvent}
                onClose={() => setSelectedEvent(undefined)}
                canEditSessions={can_edit_sessions}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const ENABLED_VIEWS = [Views.WORK_WEEK]
const FORMATS: Formats = {
  // see https://github.com/moment/luxon/blob/master/docs/formatting.md
  dayFormat: 'cccc',
  timeGutterFormat: 'HH:mm',
  eventTimeRangeFormat: (range, culture, localizer) => {
    if (!localizer) return ''
    return `${localizer.format(range.start, 'HH:mm')} - ${localizer.format(range.end, 'HH:mm')}`
  }
}

// the types says that EventInteractionArgs is stringOrDate which is a bit unhelpful
const toISOString = (arg: string | Date) => {
  if (typeof arg === 'string') {
    return arg
  } else return arg.toISOString()
}

type EventWithDeleteButtonProps = {
  onDelete: (event: CalendarEvent) => void
}
// returns a component that will use the supplied onDelete
const withDelete = ({ onDelete }: EventWithDeleteButtonProps) => {
  const result = ({ event }: { event: CalendarEvent }) => {
    return (
      <>
        <button
          aria-label="Delete"
          className="right-0 top-0 text-sm absolute hover:bg-white rounded p-0.5"
          onClick={e => {
            e.preventDefault()
            onDelete(event)
          }}
        >
          <FontAwesomeIcon icon={['far', 'trash-can']} />
        </button>
        <EventDisplay event={event} />
      </>
    )
  }
  result.displayName = 'EventWithDeleteButton'
  return result
}

const EventDisplay = ({ event }: { event: CalendarEvent }) => {
  //there is more space where all day events are displayed
  const textClass = event.type === 'snooze' ? 'text-sm' : 'text-xs'
  return <span className={textClass}>{event.title} </span>
}

const formatEvent = (event: CoachTimetableEventData): CalendarEvent => {
  return {
    ...event,
    isAllDay: event.type === 'snooze',
    start: new Date(event.start),
    end: new Date(event.end)
  }
}

const eventClassNames = (event: CalendarEvent, editable: boolean) => {
  let className = ''

  switch (event.type) {
    case 'training_session':
      className = 'bg-coralorange'
      break
    case 'coach_availability':
      if (!editable) {
        className = 'opacity-40'
      }
      break
  }

  if (event.loading) {
    className += ' sw-loading loading-lg animate-pulse'
  }

  return { className }
}

export default CoachAvailabilityEditor
