import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type Props = {
  showAllDay: boolean
  setShowAllDay: (value: boolean) => void
}
const ShowAllDayButton = ({ showAllDay, setShowAllDay }: Props) => {
  const tooltip = showAllDay ? 'Shrink' : 'Expand'
  return (
    <div className="flex h-full w-full">
      <button
        onClick={() => setShowAllDay(!showAllDay)}
        className={`mx-auto my-auto  sw-tooltip sw-btn btn-icon border-none shadow-none ${showAllDay ? 'btn-active' : ''}`}
        aria-label={tooltip}
        data-tooltip={tooltip}
      >
        <FontAwesomeIcon icon={['fas', 'up-down']} />
      </button>
    </div>
  )
}

export default ShowAllDayButton
