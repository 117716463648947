import {
  Modules,
  ModuleWithKey,
  PlannedSession,
  DayOfWeek,
  Timeslot,
  SessionDuration,
  Snooze as Pause,
  TrainingSessionState
} from './types'
import { addMinutes, isAfter, parse } from 'date-fns'
import { dayOfWeekFromJsDayNumber } from '../utils/date_helpers'
import { assertUnreachable } from '../utils/asserts'

export const newlyAddedClass = (userIds: number[], id: number) => (userIds.indexOf(id) >= 0 ? 'new-user' : undefined)

/**
 * Returns undefined if a module key is present, an error message if not
 */
export const moduleDoesntExistError = (module_keys: string[], moduleKey: string) => {
  if (!moduleKey) return
  return module_keys.includes(moduleKey) ? undefined : '⚠️ This module does not currently exist'
}

/**
 * Returns undefined if a module key is not in the plan, an error message otherwise
 */
export const moduleInSessionError = (plan: PlannedSession[], moduleKey: string) => {
  if (!moduleKey) return
  return plan.find(session => session.training_module === moduleKey)
    ? '⚠️ This module has already been added to the training plan'
    : undefined
}

/**
 * Retrieve array of modules by their keys (i.e. core_tech/module)
 */
export const getModulesFromKeys = (moduleKeys: string[], modules: Modules) => {
  const moduleArray: ModuleWithKey[] = []
  moduleKeys.forEach(key => {
    if (key in modules) {
      moduleArray.push({ ...modules[key], key })
    }
  })
  return moduleArray
}

const abbreviatedDay = (dayOfWeek: DayOfWeek): string => {
  switch (dayOfWeek) {
    case DayOfWeek.Monday:
      return 'Mon'
    case DayOfWeek.Tuesday:
      return 'Tue'
    case DayOfWeek.Wednesday:
      return 'Wed'
    case DayOfWeek.Thursday:
      return 'Thu'
    case DayOfWeek.Friday:
      return 'Fri'
    case DayOfWeek.Saturday:
      return 'Sat'
    case DayOfWeek.Sunday:
      return 'Sun'
  }
}

export const timeslotDescription = (timeslot: Timeslot): string => {
  return `${timeslot.starts_at} ${abbreviatedDay(timeslot.day_of_week)}`
}

export enum TimeslotError {
  NO_MATCHING_TIMESLOT,
  TIMESLOT_TOO_SHORT
}

/**
 * This looks for timeslot related errors of which there are two possible:
 *  - no timeslots containing the starting point
 *  - the start is containined within a timeslot, but the duration exceeds the duration of the timeslot
 *
 * The time period start point is converted to a date (in the system time zone) and then we create a date the start for each timeslot with a matching
 * day of week, also using system time zone.
 */
export const getTimeslotError = (timeslots: Timeslot[], timePeriod: SessionDuration): TimeslotError | null => {
  if (!(timePeriod.start_date && timePeriod.start_time)) {
    // no time entered yet
    return null
  }

  const sessionStart = new Date(`${timePeriod.start_date}T${timePeriod.start_time}`)

  const durations = timeslotDurations(timeslots, sessionStart)

  if (durations.length === 0) {
    return TimeslotError.NO_MATCHING_TIMESLOT
  }

  if (!timePeriod.duration_in_minutes) {
    //no duration is present, so there is nothing to complain about
    return null
  }
  const maxAvailable = maxNumber(durations)
  const duration = parseInt(timePeriod.duration_in_minutes)

  if (maxAvailable && duration > maxAvailable) {
    return TimeslotError.TIMESLOT_TOO_SHORT
  }

  return null
}

const MILLISECONDS_IN_MINUTE = 60_000

const timeslotDurations = (timeslots: Timeslot[], date: Date) => {
  const day = dayOfWeekFromJsDayNumber(date.getDay())
  const timeslotsForDay = timeslots.filter(ts => ts.day_of_week == day)

  const durations = timeslotsForDay
    .map(timeslot => {
      const timeslotStart = parse(timeslot.starts_at, 'HH:mm', date)
      // if date is withing the timeslot
      if (date >= timeslotStart && date < addMinutes(timeslotStart, timeslot.duration_in_minutes)) {
        return timeslot.duration_in_minutes - (date.getTime() - timeslotStart.getTime()) / MILLISECONDS_IN_MINUTE
      } else {
        return 0
      }
    })
    .filter(d => d > 0)

  return durations
}

const maxNumber = (values: number[]): number | undefined => {
  let max: number | undefined = undefined
  for (const value of values) {
    if (max === undefined || max < value) max = value
  }
  return max
}

/**
 * Returns an array of user ids that are currently paused indefinitely (startingFrom > start_date, end_date is null)
 *
 * Note: Comparison made by users timezone (is the pause date after the "user's now")
 */
export const getIndefinitelyPausedUserIds = (pauses?: Pause[], startingFrom?: Date) => {
  if (!pauses || !pauses.length) return []
  const indefinitePauses = pauses.filter(pause => {
    const startDate = new Date(`${pause.start_date}T00:00:00`) // user's timezone
    const now = new Date()
    return startDate && !pause.end_date && isAfter(startingFrom || now, startDate)
  })
  return [...new Set(indefinitePauses.map(pause => pause.user_id))]
}

export const scheduledState = (state: TrainingSessionState): boolean => {
  switch (state) {
    case TrainingSessionState.Scheduled:
    case TrainingSessionState.ScheduledAccepted:
    case TrainingSessionState.ScheduledDeclined:
      return true
    case TrainingSessionState.Unconfirmed:
    case TrainingSessionState.Absent:
    case TrainingSessionState.Complete:
    case TrainingSessionState.Partial:
    case TrainingSessionState.Declined:
      return false
    default:
      assertUnreachable(state)
  }
}
