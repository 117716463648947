import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { CalendarEvent } from './types'
import { DateTime } from 'luxon'
import { EditTrainingSessionPath } from '../utils/api/paths'

type Props = {
  event: CalendarEvent & { type: 'training_session' }
  onClose: () => void
  canEditSessions: boolean
}
const SidebarContent = ({ event, onClose, canEditSessions }: Props) => {
  return (
    <>
      <div className="flex items-baseline">
        <h3 className="grow">{event.module_titles.join(',')}</h3>
        <div>
          <button className="sw-btn btn-close" onClick={onClose}>
            <FontAwesomeIcon icon={['fas', 'xmark']} />
          </button>
        </div>
      </div>

      <EventTime start={event.start} end={event.end} />

      <table className="sw-table vert-header my-4">
        <tbody>
          <tr>
            <th>Company</th>
            <td>{event.company_names ? event.company_names.join(', ') : <em>Internal</em>}</td>
          </tr>
          <tr>
            <th>Learners</th>
            <td>{event.number_of_learners}</td>
          </tr>
        </tbody>
      </table>

      <div className="flex gap-2">
        <a target="_blank" rel="noreferrer" href={event.session_url} className="sw-btn btn-primary btn-sm">
          Go To Session
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </a>
        {canEditSessions && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/coaching_sessions/${event.code}/edit` satisfies EditTrainingSessionPath}
            className="sw-btn btn-sm"
          >
            Edit Session
            <FontAwesomeIcon icon={['fas', 'pencil']} />
          </a>
        )}
      </div>
    </>
  )
}

type EventTimeProps = {
  start: Date
  end: Date
}

const EventTime = ({ start: jsDateStart, end: jsDateEnd }: EventTimeProps) => {
  const start = DateTime.fromJSDate(jsDateStart)
  const end = DateTime.fromJSDate(jsDateEnd)

  const inMinutes = end.diff(start, ['minutes']).toHuman()

  return (
    <h4>
      {start.toFormat('LLLL dd HH:mm')} - {end.toFormat('HH:mm')} ({inMinutes})
    </h4>
  )
}
export default SidebarContent
