import { Controller } from '@hotwired/stimulus'
import { UserStub } from '../components/generated_types/training_session'
import { Root, createRoot } from 'react-dom/client'
import { MouseEvent } from 'react'
import CoachMessagingModal from '../components/coach_messaging_modal/coach_messaging_modal'

// Manages a coach messaging modal
//
// targets:
// - container: where the element is mounted
//
// values:
// - coaches: the array of coach data used by the coach picker
//
// actions:
// - showModal: opens the modal. Assumes that the event currentTarget has a data-coach-id attribue

export default class CoachMessagingModalController extends Controller<HTMLElement> {
  static targets = ['container']
  declare containerTarget: HTMLElement
  static values = {
    coaches: Array,
    defaultMessage: String
  }
  declare coachesValue: UserStub[]
  declare defaultMessageValue?: string

  root: Root | undefined

  showModal(event: MouseEvent<HTMLButtonElement>) {
    this.unmount()

    this.root = createRoot(this.containerTarget)
    this.root.render(
      <CoachMessagingModal
        initialMessage={this.defaultMessageValue || ''}
        coaches={this.coachesValue}
        coachId={parseInt(event.currentTarget.dataset['coachId'] || '')}
        onClose={() => this.unmount()}
      />
    )
  }

  unmount() {
    if (this.root) {
      this.root.unmount()
      this.root = undefined
    }
  }

  disconnect() {
    this.unmount()
  }
}
