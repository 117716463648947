import { Learner } from '../types'
import { round } from 'lodash'

interface Props {
  consumedPerLearner: Record<number, number>
  learners: Learner[]
}

const CreditsConsumedPerLearner = ({ learners, consumedPerLearner }: Props) => {
  return (
    <tr>
      <td className="controls">
        <span className="my-5 font-bold">Consumed Credits</span>
      </td>
      <td className="estimate-mins"></td>
      {learners.map(learner => (
        <td key={learner.user_id}>
          {learner.user_id in consumedPerLearner && (
            <span className="font-bold">{round(consumedPerLearner[learner.user_id], 2)}</span>
          )}
        </td>
      ))}
    </tr>
  )
}

export default CreditsConsumedPerLearner
