import React from 'react'
import { createRoot } from 'react-dom/client'
import { Elements } from '@stripe/react-stripe-js'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { ErrorEvent } from '@sentry/core'
import * as Sentry from '@sentry/react'
import importIcons from '@skiller-whale/style/font_awesome_config'

import QuickSightDashboard from '../components/quicksight_dashboard'
import {
  StripedForm,
  StripedPaymentMethodForm,
  StripedOneOffPayments,
  StripeFormComponent
} from '../components/stripe_form'
import '../controllers'

import TrainingPlanEditor from '../components/training_plan_editor/training_plan_editor'
import RecordingSharePanel from '../components/recording_share_panel/recording_share_panel'
import { withFeatureFlags } from '../components/utils/feature_flags/feature_flags_provider'
import OneOffPaymentForm from '../components/one_off_payment_form'
import TrainerInvoice from '../components/trainer_invoice/trainer_invoice'
import TrainingSessionEditForm from '../components/training_session_edit_form'
import TrainingSessionNewForm from '../components/training_session_new_form'
import CompanyContext from '../components/company_context/company_context'
import {
  TrainingPlanPageProps,
  SnoozesProps,
  UserStub,
  MultiPlanViewerPageProps
} from '../components/generated_types/training_plan'
import Pauses from '../components/pauses/pauses'
import { EditTrainingSessionProps, NewTrainingSessionProps } from '../components/generated_types/training_session'
import { AddToPlanPageProps } from '../components/generated_types/add_to_plan'
import AddToPlan from '../components/add_to_plan/add_to_plan'
import { LearnerModuleAccessProps } from '../components/generated_types/learner_module_accesses'
import LearnerModuleAccessEditor from '../components/learner_module_access_editor/learner_module_access_editor'
import { CurriculaPageProps, UserPlannedSessionsPageProps } from '../components/generated_types/user_plan_viewer'
import UserPlannedSessionsViewer from '../components/user_planned_sessions_viewer/user_planned_sessions_viewer'
import { CompanyContextPageProps } from '../components/generated_types/company_context'
import { StripeCustomerBillingData } from '../components/generated_types/billing'
import { MoveToPlanPageProps } from '../components/move_to_plan/types'
import MoveToPlan from '../components/move_to_plan/move_to_plan'
import CurriculaViewer from '../components/user_planned_sessions_viewer/curricula_viewer/curricula_viewer'
import { NewPaymentPageProps } from '../components/generated_types/payments'
import { TaughtModulesPageProps } from '../components/generated_types/home'
import TaughtModules from '../components/taught_modules/taught_modules'
import Comments from '../components/comments/comments'
import { CommentCreationPath } from '../components/comments/types'
import {
  CoachProfileProps,
  CoachTrainingSessionSummaryProps,
  CommentComponentProps,
  QuicksightDashboardProps,
  TrainingSessionSummaryProps
} from '../components/generated_types/components'
import TrainingSessionSummaries from '../components/training_session_summaries/training_session_summaries'
import { CurriculaStatusesPageProps } from '../components/generated_types/curriculum_status'
import CurriculaStatusViewer from '../components/curricula_status_viewer/curricula_status_viewer'
import { RecordingShares } from '../components/recording_share_panel/types'
import { ChangeRequestsReviewProps } from '../components/generated_types/change_requests'
import ChangeRequestsReview from '../components/change_requests/change_requests_review'
import { Turbo } from '@hotwired/turbo-rails'
import ModulesDatalist from '../components/training_plan_editor/components/modules_datalist'
import { CurrentUserData, withCurrentUser } from '../components/utils/current_user_data'
import MultiPlanViewer from '../components/training_plan_editor/multi_plan_viewer/multi_plan_viewer'
import { createConsumer } from '@rails/actioncable'
import withCable from '../components/utils/cable'
import PlanVersionProvider from '../components/training_plan_editor/components/use_plan_version'
import CoachTrainingSessionSummaries from '../components/training_session_summaries/coach_training_session_summaries'
import CoachProfile from '../components/coach_profile/coach_profile'
import CoachingPlanChannelProvider from '../components/training_plan_editor/components/use_coaching_plan_channel'
import { CoachAvailabilityEditorProps } from '../components/generated_types/coach_availability'
import CoachAvailabilityEditor from '../components/coach_availability_editor/coach_availability_editor'

Turbo.session.drive = false

document.addEventListener('DOMContentLoaded', () => {
  configureSentry()
  loadFontAwesome()
  renderAllStripeElements()
  renderQuickSightDashboards()

  renderTrainingSessionEditForms()
  renderNewTrainingSessionForms()
  renderTrainingPlans()
  renderMultiPlanViewer()
  renderAddToTrainingPlan()
  renderMoveToTrainingPlan()
  renderRecordingSharePanels()
  renderPaymentForms()
  renderTrainerInvoices()
  renderCompanyContexts()
  renderPauseEditor()
  renderLearnerModuleAccessEditor()
  renderUserPlannedSessionsViewer()
  renderCurriculaViewer()
  renderCurriculaStatusViewer()
  renderTaughtModules()
  renderComments()
  renderTrainingSessionSummaries()
  renderChangeRequestsReview()
  renderCoachProfiles()
  renderCoachAvailabilityEditors()
})

function getHTMLElementsByClassName(className: string) {
  return Array.from(document.getElementsByClassName(className)).filter(e => e instanceof HTMLElement)
}

function getHTMLElementsBySelector(selector: string) {
  return Array.from(document.querySelectorAll(selector)).filter(e => e instanceof HTMLElement)
}

function renderQuickSightDashboards() {
  for (const element of getHTMLElementsByClassName('quicksight-dashboard')) {
    const props = JSON.parse(checkDefined(element.dataset.props, 'props')) as QuicksightDashboardProps

    const root = createRoot(element)
    root.render(<QuickSightDashboard {...props} />)
  }
}

function renderStripeElements(selector: string, Component: StripeFormComponent) {
  const stripe_elts = getHTMLElementsByClassName(selector)
  if (stripe_elts.length > 0) {
    import('@stripe/stripe-js').then(mod => {
      const { loadStripe } = mod
      for (const stripe_elt of stripe_elts) {
        const api_key = checkDefined(stripe_elt.dataset.apiKey, 'apiKey')
        const co_id = checkDefined(stripe_elt.dataset.companyId, 'companyId')
        const sub_amount = stripe_elt.dataset.subAmount
        const sub_credits = stripe_elt.dataset.subCredits
        const si_secret = checkDefined(stripe_elt.dataset.siSecret, 'siSecret')
        const googleMapsApiKey = stripe_elt.dataset.googleMapsApiKey
        const customer_data = stripe_elt.dataset.customer
          ? (JSON.parse(stripe_elt.dataset.customer) as StripeCustomerBillingData)
          : undefined
        const stripePromise = loadStripe(api_key)
        const root = createRoot(stripe_elt)
        root.render(
          <Elements stripe={stripePromise}>
            <Component
              si_secret={si_secret}
              company_id={co_id}
              sub_amount={sub_amount}
              sub_credits={sub_credits}
              customer_data={customer_data}
              googleMapsApiKey={googleMapsApiKey}
            />
          </Elements>
        )
      }
    })
  }
}

function renderAllStripeElements() {
  renderStripeElements('js_stripe_card_element', StripedForm)
  renderStripeElements('js_stripe_new_payment_method', StripedPaymentMethodForm)
  renderStripeElements('js_stripe_one_off_element', StripedOneOffPayments)
}

function renderTrainingPlans() {
  for (const selector of getHTMLElementsByClassName('component--training-plan-edit')) {
    const { active_module_metadata, ...props } = JSON.parse(
      checkDefined(selector.dataset.props, 'props')
    ) as TrainingPlanPageProps
    let flags = {}
    if (selector.dataset.flags) {
      flags = JSON.parse(selector.dataset.flags)
    }

    let current_user: CurrentUserData | null = null
    if (selector.dataset.currentUser) {
      current_user = JSON.parse(selector.dataset.currentUser) as CurrentUserData
    }

    const lockVersions = props.training_plan.id ? { [props.training_plan.id]: props.training_plan.lock_version } : {}
    const root = createRoot(selector)
    root.render(
      withFeatureFlags(
        withCurrentUser(
          withCable(
            <>
              <ModulesDatalist modules={active_module_metadata} />
              <PlanVersionProvider lockVersions={lockVersions}>
                <TrainingPlanEditor {...props} active_module_keys={Object.keys(active_module_metadata)} />
              </PlanVersionProvider>
            </>,
            props.editable ? createConsumer('/cable') : null
          ),
          current_user
        ),
        flags
      )
    )
  }
}

function renderMultiPlanViewer() {
  for (const selector of getHTMLElementsByClassName('multi-plan-viewer')) {
    const props = JSON.parse(checkDefined(selector.dataset.props, 'props')) as MultiPlanViewerPageProps

    const initialVersions = Object.fromEntries(props.training_plans.map(tp => [tp.id, tp.lock_version]))
    const root = createRoot(selector)
    root.render(
      withCable(
        <CoachingPlanChannelProvider planIds={props.training_plans.map(tp => tp.id)}>
          <PlanVersionProvider lockVersions={initialVersions}>
            <MultiPlanViewer {...props} />
          </PlanVersionProvider>
        </CoachingPlanChannelProvider>,
        createConsumer('/cable')
      )
    )
  }
}

function renderAddToTrainingPlan() {
  for (const selector of getHTMLElementsByClassName('add-to-training-plan')) {
    if (!selector.dataset.props) {
      warn('Missing props on add-to-training-plan')
      continue
    }

    const data = JSON.parse(selector.dataset.props) as AddToPlanPageProps

    const root = createRoot(selector)
    root.render(<AddToPlan {...data} />)
  }
}

function renderMoveToTrainingPlan() {
  for (const selector of getHTMLElementsByClassName('move-to-training-plan')) {
    if (!selector.dataset.props) {
      warn('Missing props on move-to-training-plan')
      continue
    }

    const data = JSON.parse(selector.dataset.props) as MoveToPlanPageProps

    const root = createRoot(selector)
    root.render(<MoveToPlan {...data} />)
  }
}

function renderRecordingSharePanels() {
  for (const selector of getHTMLElementsByClassName('recording-share-panel')) {
    const userData = JSON.parse(checkDefined(selector.dataset.users, 'users')) as UserStub[]
    const shareData = JSON.parse(checkDefined(selector.dataset.shares, 'shares')) as RecordingShares
    const trainingSessionCode = checkDefined(selector.dataset.trainingSessionCode)
    const root = createRoot(selector)
    root.render(<RecordingSharePanel users={userData} shares={shareData} trainingSessionCode={trainingSessionCode} />)
  }
}

function renderPaymentForms() {
  for (const selector of getHTMLElementsByClassName('new-one-off-payment-form')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as NewPaymentPageProps

    const root = createRoot(selector)
    root.render(<OneOffPaymentForm {...data} />)
  }
}

function renderTrainerInvoices() {
  for (const selector of getHTMLElementsByClassName('trainer-invoice-detail')) {
    const invoice = JSON.parse(checkDefined(selector.dataset.invoice, 'invoice'))
    const readOnly = JSON.parse(checkDefined(selector.dataset.readOnly, 'readonly'))

    const root = createRoot(selector)
    root.render(<TrainerInvoice invoice={invoice} readOnly={readOnly} />)
  }
}

function renderCoachProfiles() {
  for (const selector of getHTMLElementsByClassName('coach-profile-component')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CoachProfileProps

    const root = createRoot(selector)
    root.render(<CoachProfile {...data} />)
  }
}

function renderCoachAvailabilityEditors() {
  for (const selector of getHTMLElementsBySelector('div[data-component=CoachAvailabilityEditor')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CoachAvailabilityEditorProps

    const root = createRoot(selector)
    root.render(<CoachAvailabilityEditor {...data} />)
  }
}

function renderTrainingSessionEditForms() {
  for (const selector of getHTMLElementsByClassName('training-session-edit-form')) {
    const {
      training_session,
      url,
      trainers,
      modules,
      line_items,
      users,
      available_internal_users,
      attendances,
      module_duration_overrides,
      plan_memberships,
      coach_messaging_enabled
    } = JSON.parse(checkDefined(selector.dataset.form, 'form')) as EditTrainingSessionProps

    let flags = {}
    if (selector.dataset.flags) {
      flags = JSON.parse(selector.dataset.flags)
    }

    let current_user: CurrentUserData | null = null
    if (selector.dataset.currentUser) {
      current_user = JSON.parse(selector.dataset.currentUser) as CurrentUserData
    }

    const root = createRoot(selector)
    root.render(
      withFeatureFlags(
        withCurrentUser(
          <TrainingSessionEditForm
            url={url}
            trainingSession={training_session}
            availableInternalUsers={available_internal_users}
            lineItems={line_items}
            users={users}
            trainers={trainers}
            modules={modules}
            attendances={attendances}
            moduleDurationOverrides={module_duration_overrides}
            planMemberships={plan_memberships}
            coachMessagingEnabled={coach_messaging_enabled}
          />,
          current_user
        ),
        flags
      )
    )
  }
}

function renderNewTrainingSessionForms() {
  for (const selector of getHTMLElementsByClassName('training-session-new-form')) {
    const { training_session, url, trainers, modules, users, module_duration_overrides } = JSON.parse(
      checkDefined(selector.dataset.form, 'form')
    ) as NewTrainingSessionProps

    let flags = {}
    if (selector.dataset.flags) {
      flags = JSON.parse(selector.dataset.flags)
    }

    let current_user: CurrentUserData | null = null
    if (selector.dataset.currentUser) {
      current_user = JSON.parse(selector.dataset.currentUser) as CurrentUserData
    }

    const root = createRoot(selector)
    root.render(
      withFeatureFlags(
        withCurrentUser(
          <TrainingSessionNewForm
            url={url}
            trainingSession={training_session}
            users={users}
            trainers={trainers}
            modules={modules}
            moduleDurationOverrides={module_duration_overrides}
          />,
          current_user
        ),
        flags
      )
    )
  }
}

function renderCompanyContexts() {
  for (const selector of getHTMLElementsByClassName('company-context')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CompanyContextPageProps
    const root = createRoot(selector)
    root.render(<CompanyContext {...data} />)
  }
}

function renderPauseEditor() {
  for (const selector of getHTMLElementsByClassName('snoozes-editor')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as SnoozesProps
    const titleElement = selector.dataset.titleElement || 'h2'
    const titleContent = selector.dataset.titleContent || 'Pauses'
    const subtitle = selector.dataset.subtitle
    const context = { companyId: data.context.company_id, user: data.context.user }
    const root = createRoot(selector)

    root.render(
      <Pauses
        title={React.createElement(titleElement, {}, titleContent)}
        subtitle={subtitle && <p>{subtitle}</p>}
        pauses={data.snoozes}
        context={context}
        showPlanPicker={data.show_plan_picker}
        editable={data.editable}
      />
    )
  }
}

function renderLearnerModuleAccessEditor() {
  for (const selector of getHTMLElementsByClassName('learner-module-access-editor')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as LearnerModuleAccessProps
    const root = createRoot(selector)
    root.render(<LearnerModuleAccessEditor {...data} />)
  }
}

function renderUserPlannedSessionsViewer() {
  for (const selector of getHTMLElementsByClassName('user-planned-sessions')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as UserPlannedSessionsPageProps
    const root = createRoot(selector)
    root.render(<UserPlannedSessionsViewer {...data} />)
  }
}

function renderCurriculaViewer() {
  for (const selector of getHTMLElementsByClassName('curricula-viewer')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CurriculaPageProps
    const root = createRoot(selector)
    root.render(<CurriculaViewer {...data} />)
  }
}

function renderTaughtModules() {
  for (const selector of getHTMLElementsByClassName('taught-modules')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as TaughtModulesPageProps
    const root = createRoot(selector)
    root.render(<TaughtModules {...data} />)
  }
}

function renderComments() {
  for (const selector of getHTMLElementsByClassName('comments-editor')) {
    const {
      path,
      title_element = 'h2',
      title_content = 'Comments',
      ...data
    } = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CommentComponentProps
    const root = createRoot(selector)
    root.render(
      <Comments
        {...data}
        path={path as CommentCreationPath}
        title={React.createElement(title_element, {}, title_content)}
      />
    )
  }
}

function renderTrainingSessionSummaries() {
  for (const selector of getHTMLElementsByClassName('training-session-summaries')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as TrainingSessionSummaryProps
    const root = createRoot(selector)
    root.render(<TrainingSessionSummaries {...data} />)
  }

  for (const selector of getHTMLElementsByClassName('coach-training-session-summaries')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CoachTrainingSessionSummaryProps
    const root = createRoot(selector)
    root.render(<CoachTrainingSessionSummaries {...data} />)
  }
}

function renderCurriculaStatusViewer() {
  for (const selector of getHTMLElementsByClassName('curricula-status-viewer')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as CurriculaStatusesPageProps
    const root = createRoot(selector)
    root.render(<CurriculaStatusViewer {...data} />)
  }
}

function renderChangeRequestsReview() {
  for (const selector of getHTMLElementsByClassName('change-requests-review')) {
    const data = JSON.parse(checkDefined(selector.dataset.props, 'props')) as ChangeRequestsReviewProps
    const root = createRoot(selector)
    root.render(<ChangeRequestsReview {...data} />)
  }
}

function configureSentry() {
  const sentryDsn = document.getElementsByTagName('body')[0].dataset.sentryDsn
  if (sentryDsn) {
    Sentry.init({
      beforeSend: sentryEventFilter,
      dsn: sentryDsn,
      release: document.getElementsByTagName('body')[0].dataset.sentryRelease
    })
  }
}

function loadFontAwesome() {
  importIcons()
  dom.watch()
}

/*
  we get a certain amount of sentry spam from automated feature detction code.
  These bots load our app and test for the presence of various globals (eg a global OT object implies that you use opentok).
  Each check that fails creates an alert in sentry, which is annoying

  This uses sentry's filtering ( https://docs.sentry.io/platforms/javascript/configuration/filtering/) so that if an error matches all of:
  - on the sign in page
  - that have an original exception (ie not from captureMessage)
  - that have no useful stacktrace (since they are eval-ing at the top level)
  - where the error is 'is not defined'

  then we set the fingerprint to 'feature-detection-spam'. This allows us to easily exclude from within sentry whilst still preserving data
  if we should have a false positive
*/
function sentryEventFilter(event: ErrorEvent, hint: Sentry.EventHint) {
  const onSignInPage = typeof event.request?.url == 'string' && event.request?.url.endsWith('/users/sign_in')
  const parsedException = event.exception?.values && event.exception?.values[0]
  if (
    onSignInPage &&
    hint.originalException instanceof Error &&
    hint.originalException.message.endsWith('is not defined') &&
    parsedException &&
    errorHasNoStackTrace(parsedException)
  ) {
    event.fingerprint = ['feature-detection-spam']
  }
  return event
}

function errorHasNoStackTrace(error: Sentry.Exception) {
  if (!error?.stacktrace?.frames) {
    return true
  }
  if (error.stacktrace.frames.every(frame => frame.filename === '<anonymous>' && frame.function === '?')) {
    return true
  }
  return false
}

function warn(message: string) {
  console && console.error(message)
  Sentry.captureMessage(message)
}

/**
 * Helper function to check for and report undefined/null data
 * Returns NotNullOrUndefined<T> equivalent
 */
function checkDefined<T>(field: T | undefined | null, fieldName?: string) {
  if (typeof field === 'undefined' || field === null) {
    const message = 'Undefined/null data on page' + fieldName ? ` (${fieldName})` : ''
    Sentry.captureMessage(message)
    throw new Error(message)
  }
  return field
}
