import { useState, ReactNode, ReactElement, useContext, MouseEvent } from 'react'
import SWFormGroup from '../library/sw/sw_form_group'
import SWHorizontalFieldWithLabel from '../library/sw/sw_horizontal_field_with_label'
import SWCheckbox from '../library/sw/sw_checkbox'
import TrainingSessionDuration from '../training_session_duration'
import ModuleSelector from './module_selector'
import { Formik, Form, Field, FormikBag, FormikProps } from 'formik'
import jsonRequest from '../utils/json_request'

import {
  Attendance,
  SessionType,
  ExistingTrainingSession,
  NewTrainingSession,
  TrainingSession,
  TrainingModules,
  UserStub,
  SessionDetailsFormikValues,
  LineItem,
  TrainingSessionPayload,
  TrainingSessionResponse,
  ModuleDurationOverrides,
  VideoProvider,
  ConfirmationErrors,
  SessionDetailsFormikStatus
} from './types'

import TrainerInvoiceWarning from './trainer_invoice_warning'
import { objectRemoveNulls } from '../utils/object_helpers'
import TrainerSelector from '../library/trainer_selector'
import Flags from '../utils/feature_flags/feature_flags'
import { FeatureFlagsContext } from '../utils/feature_flags/feature_flags_provider'
import { extractSessionConfirmationErrors, formikStateFromTrainingSession, sumModuleDurations } from './utils'
import SessionTypeWarning from './session_type_warning'
import ConfirmationWarningsModal from './confirmation_warnings_modal'
import CoachInfo from './coach_info'
import { TrainerInvoiceStatus } from '../trainer_invoice/constants'
import SessionTypeField from './session_type_field'
import StatusDisplay from './status_display'
import { PlanMembership } from '../generated_types/training_session'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import CoachMessagingModal from '../coach_messaging_modal/coach_messaging_modal'
import CoachNewContentWarning from './coach_new_content_warning'
import { CurrentUserContext, CurrentUserData } from '../utils/current_user_data'
import ReactPortal from '@skiller-whale/style/components/react_portal'
import { fromZonedTime } from 'date-fns-tz'
import { getLongDateOnly, getShortTime } from '../utils/date_helpers'
import FindCoachButton from './find_coach_button'

type SessionDetailProps<T> = {
  url: string
  trainingSession: T
  setTrainingSession?: (v: T) => void
  setLineItems?: (v: LineItem[]) => void
  attendances: Attendance[]
  setAttendances?: (v: Attendance[]) => void
  setPlanMemberships?: (p: PlanMembership[]) => void
  children?: ReactNode
  trainers: UserStub[]
  modules: TrainingModules
  moduleDurationOverrides: ModuleDurationOverrides
  coachMessagingEnabled: boolean
}

function SessionDetails(props: SessionDetailProps<ExistingTrainingSession>): ReactElement
function SessionDetails(props: SessionDetailProps<NewTrainingSession>): ReactElement
function SessionDetails({
  url,
  trainingSession,
  setTrainingSession,
  trainers,
  modules,
  attendances,
  children,
  setLineItems,
  setAttendances,
  setPlanMemberships,
  moduleDurationOverrides,
  coachMessagingEnabled
}: SessionDetailProps<TrainingSession>) {
  const currentUser = useContext(CurrentUserContext)
  const [coachMessagingModalOpen, setCoachMessagingModalOpen] = useState(false)
  const [confirmErrors, setConfirmErrors] = useState<ConfirmationErrors | null>(null)

  const actionLabel = trainingSession.persisted ? 'Update' : 'Create'
  const trainerInvoice = trainingSession.persisted ? trainingSession.trainer_invoice : null

  const showTrainerInvoiceWarning = (values: SessionDetailsFormikValues) =>
    trainerInvoice &&
    trainingSession.duration_in_minutes !== values.duration_in_minutes &&
    trainerInvoice.status !== TrainerInvoiceStatus.Complete

  const scrollToTop = () => {
    const mainElements = document.getElementsByTagName('main') // This will be called async, so get element each time (it may have re-rendered)
    if (mainElements.length) mainElements[0].scrollTo({ top: 0, behavior: 'smooth' })
  }

  const onSubmit = async (
    formData: SessionDetailsFormikValues,
    actions: FormikBag<FormikProps<SessionDetailsFormikValues>, SessionDetailsFormikValues>
  ) => {
    actions.setStatus() //reset formik response status
    setConfirmErrors(null)

    let payload: TrainingSessionPayload

    if (trainingSession.persisted) {
      payload = {
        ...formData,
        previous_user_ids: attendances.map(att => att.user_id),
        previous_start: trainingSession.start,
        previous_duration_in_minutes: trainingSession.duration_in_minutes,
        previous_modules: trainingSession.modules
      }
    } else {
      payload = {
        ...formData,
        required_modules_for_followup: trainingSession.required_modules_for_followup,
        suggested_initial_slide: trainingSession.suggested_initial_slide || undefined,
        preceding_session_code: trainingSession.preceding_session_code || undefined
      }
    }
    if (!showTrainerInvoiceWarning(formData)) {
      delete payload.adjust_trainer_invoice
    }

    // TODO: Replace with apiRequest calls
    const response = await jsonRequest<
      { training_session: Partial<TrainingSessionPayload> },
      undefined,
      TrainingSessionResponse
    >(url, {
      method: trainingSession.persisted ? 'PATCH' : 'POST',
      payload: {
        training_session: objectRemoveNulls(payload)
      }
    })
    const { ok, data } = response

    if (ok) {
      if ('edit_session_url' in data) {
        // when creating a new session, post update we redirect to the specified location
        window.location.assign(data.edit_session_url)
        // when editing an existing training session, the post update action is to reset the forms' initial state to the new values
      } else {
        actions.resetForm({
          values: formikStateFromTrainingSession(data.training_session),
          status: { message: data.message }
        })
        if (setTrainingSession) {
          setTrainingSession(data.training_session)
        }
        if (setLineItems) {
          setLineItems(data.line_items)
        }
        if (setAttendances) {
          setAttendances(data.attendances)
        }
        if (setPlanMemberships) {
          setPlanMemberships(data.plan_memberships)
        }
      }
    } else {
      // if the save is unsuccessful
      const { confirmationErrors } = extractSessionConfirmationErrors(response.errors)

      if (confirmationErrors.all.length > 0) {
        setConfirmErrors(confirmationErrors)
      } else {
        actions.setStatus({ errors: response.errors })
      }
    }
    scrollToTop()
  }

  const disabled =
    trainingSession.session_type === SessionType.Fake || ('cancelled' in trainingSession && trainingSession.cancelled)

  const showHostedEnvironmentCustomisations = useContext(FeatureFlagsContext)[Flags.HostedEnvironmentCustomisation]

  const copySessionDetails = (e: MouseEvent<HTMLButtonElement>, values: SessionDetailsFormikValues) => {
    e.preventDefault()
    e.stopPropagation()

    const modules_description = buildModulesDescription(values.modules, modules)

    // timezones are complicated:
    // new Date(...) parses the string in the users local zone, eg if the session time is midday and the user
    // is on the east cost of the US, this is parsed as midday EST (you can force parcing in UTC but that doesn't help).
    //
    //fromTimeZone re-interprets this as if the original data was in Europe/London, this produces 7am EST
    // finally getShortTime/getLongDateOnly format in the Europe/London time zone so we get 12:00 as the output
    const date = fromZonedTime(new Date(`${values.start_date}T${values.start_time}`), 'Europe/London')
    const description = `${modules_description} on ${getLongDateOnly(date)} at ${getShortTime(date)}`
    navigator.clipboard.writeText(description)
  }

  return (
    <>
      <Formik<SessionDetailsFormikValues>
        onSubmit={onSubmit}
        initialValues={formikStateFromTrainingSession(trainingSession)}
      >
        {({
          isSubmitting,
          values,
          status,
          setFieldValue,
          setStatus
        }: Omit<FormikProps<SessionDetailsFormikValues>, 'status'> & { status: SessionDetailsFormikStatus }) => {
          const durationChanged = trainingSession.duration_in_minutes !== values.duration_in_minutes

          // relevant overrides are module duration overrides for modules that are in the session
          const relevantOverrides = Object.fromEntries(
            Object.entries(moduleDurationOverrides).filter(([key, _o]) => values.modules.includes(key))
          )

          return (
            <>
              {trainingSession.persisted && (
                <ReactPortal targetId="session-actions" position="prepend">
                  <button onClick={e => copySessionDetails(e, values)} className="sw-btn">
                    <FontAwesomeIcon icon={['fas', 'copy']} />
                    Copy Session Details
                  </button>
                </ReactPortal>
              )}
              <Form className={`session-details${isSubmitting ? ' loading' : ''}`}>
                <StatusDisplay status={status} resetStatus={() => setStatus()} />

                {confirmErrors && (
                  <ConfirmationWarningsModal
                    confirmErrors={confirmErrors}
                    submitting={isSubmitting}
                    onClose={() => setConfirmErrors(null)}
                    onConfirm={() => {
                      if (confirmErrors.availabilityErrors.length) setFieldValue('permit_availability_clash', true)
                      if (confirmErrors.changeRequestErrors.length) setFieldValue('permit_change_request_clash', true)
                    }}
                  />
                )}

                <SWHorizontalFieldWithLabel
                  label="on"
                  name="start_date"
                  type="date"
                  className="sw-input"
                  disabled={disabled}
                />

                <SWHorizontalFieldWithLabel
                  label="at"
                  name="start_time"
                  type="time"
                  className="sw-input"
                  disabled={disabled}
                />

                <TrainingSessionDuration
                  value={`${values.duration_in_minutes}`}
                  trainerInvoice={trainerInvoice}
                  durationChanged={durationChanged}
                  estimatedMinutes={sumModuleDurations({
                    modules,
                    selected: values.modules,
                    overrides: moduleDurationOverrides
                  })}
                  overrides={relevantOverrides}
                  disabled={disabled}
                />

                <SWFormGroup>
                  <Field
                    name="catchup"
                    type="checkbox"
                    as={SWCheckbox}
                    label="Catchup Session (for people who have missed a planned session)"
                    disabled={disabled}
                  />
                </SWFormGroup>

                {showTrainerInvoiceWarning(values) ? <TrainerInvoiceWarning /> : null}

                <div className="sw-snippet flex-col">
                  <SWFormGroup classNames="justify-start flex-row items-baseline">
                    <SessionTypeField session_type={values.session_type} disabled={disabled} />
                    <SessionTypeWarning
                      session_type={values.session_type}
                      selectedModules={values.modules}
                      moduleData={modules}
                    />
                  </SWFormGroup>
                  <SWFormGroup>
                    <Field
                      name="permit_unpublished"
                      type="checkbox"
                      as={SWCheckbox}
                      label="Allow sessions with unpublished modules"
                      disabled={disabled}
                    />
                  </SWFormGroup>
                  {trainingSession.persisted ? (
                    <SWFormGroup>
                      <Field
                        name="permit_modules_change_after_setup_sent"
                        type="checkbox"
                        as={SWCheckbox}
                        label="Allow changing modules after setup email has been sent"
                        disabled={disabled}
                      />
                    </SWFormGroup>
                  ) : null}

                  {showHostedEnvironmentCustomisations && (
                    <SWFormGroup>
                      <Field
                        name="auto_start_hosted_environments"
                        type="checkbox"
                        as={SWCheckbox}
                        label="Automatically start hosted environments for all learners prior to scheduled session time"
                        disabled={disabled}
                      />
                    </SWFormGroup>
                  )}
                </div>

                <datalist id="modules_datalist">
                  {Object.entries(modules)
                    .filter(([_, value]) => !value.deprecated)
                    .map(([key, value]) => (
                      <option key={key} value={key}>
                        {value.curriculum_title}: {value.module_title}
                      </option>
                    ))}
                </datalist>
                <ModuleSelector
                  name="training_session_modules_list"
                  datalist_id="modules_datalist"
                  moduleData={modules}
                  modules={values.modules}
                  setModules={updater => setFieldValue('modules', updater(values.modules))}
                  multiple={true}
                  readonly={disabled}
                />

                <TrainerSelector
                  name="trainer_id"
                  label="Coach"
                  trainers={trainers}
                  onTrainerChanged={trainer_id => setFieldValue('trainer_id', trainer_id || '')}
                  initialTrainerID={trainingSession.trainer_id || undefined}
                >
                  <CoachInfo trainingSession={trainingSession} formTrainerId={values.trainer_id} />
                  {coachMessagingEnabled && (
                    <button
                      className="sw-btn btn-sm ml-4"
                      onClick={e => {
                        e.preventDefault()
                        setCoachMessagingModalOpen(true)
                      }}
                    >
                      <FontAwesomeIcon icon={['fab', 'slack']} />
                      Message Coach
                    </button>
                  )}
                  {trainingSession.persisted ? (
                    <a
                      href={`/coaching_sessions/${trainingSession.code}/replacement_coaches`}
                      className="sw-btn btn-sm ml-4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
                      Find Replacement
                    </a>
                  ) : (
                    <FindCoachButton
                      durationInMinutes={values.duration_in_minutes}
                      learnerIds={values.user_ids}
                      start_date={values.start_date}
                      start_time={values.start_time}
                      moduleKeys={values.modules}
                    />
                  )}
                </TrainerSelector>

                <div>
                  <CoachNewContentWarning
                    modules={modules}
                    className="mb-2"
                    coachName={trainers.find(c => c.id === values.trainer_id)?.name}
                  />
                </div>

                {'video_provider' in trainingSession && (
                  <SWHorizontalFieldWithLabel
                    width={5}
                    name="video_provider"
                    as="select"
                    className="sw-select"
                    label="Video"
                    disabled={disabled}
                  >
                    <option label="Opentok" value={VideoProvider.Opentok}>
                      Opentok
                    </option>
                    <option label="Daily" value={VideoProvider.Daily}>
                      Daily
                    </option>
                  </SWHorizontalFieldWithLabel>
                )}

                {children}

                <div className="flex gap-6 my-8">
                  <div className="sw-divider grow" />
                  <button
                    type="submit"
                    className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                    disabled={disabled}
                  >
                    <i className="fa-solid fa-chalkboard-user" />
                    {actionLabel}
                  </button>
                </div>
              </Form>
              {coachMessagingModalOpen && (
                <CoachMessagingModal
                  coaches={trainers}
                  onClose={() => setCoachMessagingModalOpen(false)}
                  coachId={values.trainer_id || undefined}
                  initialMessage={buildCoachSlackMessage(values, currentUser, modules)}
                />
              )}
            </>
          )
        }}
      </Formik>
    </>
  )
}

const buildCoachSlackMessage = (
  values: SessionDetailsFormikValues,
  currentUser: CurrentUserData,
  modules: TrainingModules
): string => {
  const module_description = buildModulesDescription(values.modules, modules)

  return [
    'Hello 👋',
    `We're looking for someone to coach a session on ${module_description} on ${values.start_date} at ${values.start_time} (for ${values.duration_in_minutes} minutes).`,
    `Are you interested / available?`,
    '',
    `Thanks so much!`
  ].join('\n')
}

const buildModulesDescription = (keys: string[], modules: TrainingModules) =>
  keys
    .map(key => {
      const moduleData = modules[key]
      return moduleData ? `${moduleData.module_title} (${moduleData.curriculum_title})` : key
    })
    .join(', ')

export default SessionDetails
