// This file is automatically generated by running rails typescript:update_paths

export type CompanyCommentsPath = `/companies/${number}/comments`
export type CommentPath = `/comments/${number}`
export type TrainingPlanCommentsPath = `/plans/${number}/comments`
export type TrainingPlanPath = `/plans/${number}`
export type CompanyTrainingPlansPath = `/companies/${number}/plans`
export type CompanyPath = `/companies/${number}`
export const UserUnavailabilitiesPath = `/user_unavailabilities`
export type UserCommentsPath = `/users/${number}/comments`
export type SnoozePath = `/snoozes/${number}`
export const SnoozesPath = `/snoozes`
export type CompanyTrainingSessionsPath = `/companies/${number}/coaching_sessions`
export type TrainingSessionPath = `/coaching_sessions/${string}/`
export type UserLearnerModuleAccessesPath = `/users/${number}/learner_module_accesses`
export type LearnerModuleAccessPath = `/learner_module_accesses/${number}`
export type TrainingSessionCancellationPath = `/coaching_sessions/${string}/cancellation`
export type TrainingSessionAttendancesPath = `/coaching_sessions/${string}/attendances`
export const PlannedSessionsConfirmationProfilePath = `/profile/planned_sessions_confirmation`
export const TaughtModulesPath = `/taught_modules`
export type TrainingPlanTimeslotsPath = `/plans/${number}/timeslots`
export type PastSessionsUserPath = `/users/${number}/past_sessions`
export type TrainerInvoicePath = `/invoices/${number}`
export type TrainerInvoiceTrainerInvoiceLineItemsPath = `/invoices/${number}/trainer_invoice_line_items`
export type TrainerInvoiceLineItemPath = `/trainer_invoice_line_items/${number}`
export type MoveTrainerInvoicePath = `/invoices/${number}/move`
export const SearchCurriculaStatusesPath = `/curricula/statuses/search`
export const ChangeRequestsPath = `/change_requests`
export type ChangeRequestPath = `/change_requests/${number}`
export const CreateOrUpdateChangeRequestReviewsPath = `/change_request_reviews/create_or_update`
export const ReviewableChangeRequestsPath = `/change_requests/reviewable`
export type UserStatusTrainingPlanPath = `/plans/${number}/users/${number}/status`
export type ModuleStatusTrainingPlanPath = `/plans/${number}/modules/${string}/status`
export type CompanyCompanyPausesPath = `/companies/${number}/company_pauses`
export type CompanyPausePath = `/company_pauses/${number}`
export const NewCoachSlackMessagePath = `/coach_slack_messages/new`
export const CoachSlackMessagesPath = `/coach_slack_messages`
export const PreparedModulesPath = `/prepared_modules`
export const CoachAttendanceDeclinesPath = `/coach_attendance_declines`
export type AcknowledgeOverlappingSessionsSnoozePath = `/snoozes/${number}/acknowledge_overlapping_sessions`
export type MultiTrainingPlanPath = `/plans/${number}/multi`
export type UserPath = `/users/${number}`
export type EditTrainingSessionPath = `/coaching_sessions/${string}/edit`
export type UserCoachProfilePath = `/users/${number}/coach_profile`
export type ConfirmCreditsTrainingSessionLineItemPath =
  `/coaching_sessions/${string}/line_items/${number}/confirm_credits`
export const ProfileCoachAvailabilitiesPath = `/profile/coach_availabilities`
export type CoachAvailabilityPath = `/coach_availabilities/${number}`
export type UserCoachAvailabilitiesPath = `/users/${number}/coach_availabilities`
